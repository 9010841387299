<template lang="pug">
.dashboard-wrapper
  b-row
    b-col
      b-card(no-body, class="pt-2 px-2")
        filters(
          :zoneFilterShow="zone_agency_filter"
          :agencyFilterShow="zone_agency_filter"
          :locationFilterShow="true",
          :roleFilterShow="true",
          :processFilterShow="true",
          :workerFilterShow="true",
          :observerFilterShow="true",
          :dateRangeFilterShow="true",
          @changeFilter="updateFilter"
        )
        
  b-row(v-if="isLoading")
    b-col
      b-card(class="py-5")
        loading(
          :active="true" 
          :is-full-page="false"
          color="#498ceb"
        )

  div(v-else)
    b-row(v-if="!confirmations_total")
      b-col
        b-card(class="text-center")
          h5(class="mb-0")
            | {{ $t('message.no_confirmations_found') }}

    div(v-else)
      b-row
        b-col(sm="3")
          statistic-card-vertical-total(:statistic="confirmations_total")
        b-col(sm="3")
          statistic-card-vertical-index(:statistic="adherence_index")
      
      b-row
        b-col(cols="12")
          bar-chart-process(
            v-if="adherenceByProcess.labels && adherenceByProcess.labels.length",
            :process-data="adherenceByProcess"
          )
      
      b-row
        b-col(lg="6")
          bar-chart-worker-top(
            v-if="workerTopAdherence.labels && workerTopAdherence.labels.length",
            :worker-top-data="workerTopAdherence"
          )
        b-col(lg="6")
          bar-chart-worker-bottom(
            v-if="workerBottomAdherence.labels && workerBottomAdherence.labels.length",
            :worker-bottom-data="workerBottomAdherence"
          )

      //- b-card
        b-table-simple(hover, small, caption-top, responsive)
          b-thead(head-variant="light")
            b-tr
              th {{ $t('message.tableHeader.date') }}
              th {{ $t('message.tableHeader.worker') }}
              th {{ $t('message.tableHeader.observer') }}
              th {{ $t('message.tableHeader.location') }}
              th {{ $t('message.tableHeader.process') }}
              th {{ $t('message.tableHeader.score') }}
              th {{ $t('message.tableHeader.total') }}
              th {{ $t('message.tableHeader.adherence') }}
              th {{ $t('message.tableHeader.action') }}
            b-tr(v-for="(confirmation, index) in confirmations", :key="index")
              b-td {{ confirmation.dateString }}
              b-td {{ confirmation.worker }}
              b-td {{ confirmation.observer }}
              b-td {{ confirmation.location }}
              b-td {{ confirmation.process }}
              b-td {{ confirmation.score }}
              b-td {{ confirmation.total }}
              b-td {{ confirmation.adherenceText() }}
              b-td
                view-button(@clicked="router.push({name: 'habit-confirmation-view', params: {id: confirmation.id}})")
                user-button(@clicked="router.push({name: 'apps-users-view', params: {id: confirmation.workerId}})")
      
      b-card
        b-table(
          :items="confirmations"
          :fields="tableHeaders"
          hover
          responsive
        )
          template(#cell(date)="data")
            span {{ data.item.dateString }}
          template(#cell(adherence)="data")
            span {{ data.item.adherenceText() }}
          template(#cell(actions)="data")
            div(class="flex flex-column")
              view-button(@clicked="router.push({name: 'habit-confirmation-view', params: {id: data.item.id}})")
              user-button(@clicked="router.push({name: 'apps-users-view', params: {id: data.item.workerId}})")
      
      b-button(:disabled="!confirmations.length")
        json-excel(:data="confirmations", :fields="excelFields")
          | {{ $t('download_xlsx') }}
</template>

<script>
import { queryConfirmationsScore } from "@/@core/queries/confirmations";
import { onMounted, ref, computed } from "@vue/composition-api/dist/vue-composition-api";
import axios from "@axios";
import useNotifications from "@/composables/useNotifications";
import { BTable, BTableSimple, BThead, BTr, BTd, BCard } from "bootstrap-vue";
import JsonExcel from "vue-json-excel";
import StatisticCardVerticalTotal from './charts/StatisticCardVerticalTotal.vue'
import StatisticCardVerticalIndex from './charts/StatisticCardVerticalIndex.vue'
import BarChartProcess from './charts/ChartjsBarChartProcess.vue'
import BarChartWorkerTop from './charts/ChartjsHorizontalBarChartWorkerTop.vue'
import BarChartWorkerBottom from './charts/ChartjsHorizontalBarChartWorkerBottom.vue'
import Filters from "@/views/organization/Filters.vue";
import i18n from '@/libs/i18n'
import ViewButton from '@/views/components/Shared/Buttons/ViewButton.vue'
import UserButton from '@/views/components/Shared/Buttons/UserButton.vue'
import { useRouter } from '@core/utils/utils'
import useCommonDashboards from '@/views/habit/useCommonDashboards'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import store from "@/store";
import useCommon from '@/views/organization/useCommon'
import { EventBus } from "@/views/habit/EventBus.js"

export default {
  components: {
    BTable,
    BTableSimple,
    BThead,
    BTr,
    BTd,
    BCard,
    JsonExcel,
    StatisticCardVerticalTotal,
    StatisticCardVerticalIndex,
    Filters,
    ViewButton,
    UserButton,
    BarChartProcess,
    BarChartWorkerTop,
    BarChartWorkerBottom,
    Loading,
  },
  setup() {
    const { showSuccessMessage, showErrorMessage } = useNotifications();
    const { handleError } = useCommon()
    
    const userData = store.state?.userStore?.userData;
    const clientId = userData.role !== "admin" ? userData.client.$oid : null;
    const userLocations = userData.locations?.length ? userData.locations : null;
    const confirmations = ref([]);
    const confirmations_total = ref(0);
    const adherence_index = ref(0);
    const adherenceByProcess = ref({})
    const workerTopAdherence = ref({})
    const workerBottomAdherence = ref({})
    const locationFilter = ref([]);
    const roleFilter = ref([]);
    const processFilter = ref([]);
    const workerFilter = ref([]);
    const observerFilter = ref([]);
    const zoneFilter = ref([]);
    const agencyFilter = ref([]);
    const now = new Date()
    const currentMonth = now.getMonth()
    const currentYear = now.getFullYear()
    const lastDayOfMonthDate = new Date(currentYear, currentMonth + 1, 0)
    const lastDayOfMonthNumber = lastDayOfMonthDate.getDate()
    const dateRangeFilter = ref(`01-${currentMonth + 1}-${currentYear} to ${lastDayOfMonthNumber}-${currentMonth + 1}-${currentYear}`)
    const excelFields = ref({})
    const { route, router } = useRouter()
    const { processAdherence, getDatesFromRange } = useCommonDashboards()
    const isLoading = ref(true)
    const { zone_agency_filter, default_language } = JSON.parse(localStorage.getItem('clientData') || '{}')

    // Table Headers
    const tableHeaders = computed(() => {
      return [
        {
          key: 'date',
          label: i18n.t('message.tableHeader.date'),
          sortable: true,
        },
        {
          key: 'worker',
          label: i18n.t('message.tableHeader.worker'),
          sortable: true,
        },
        {
          key: 'observer',
          label: i18n.t('message.tableHeader.observer'),
          sortable: true,
        },
        // {
        //   key: 'location',
        //   label: i18n.t('message.tableHeader.location'),
        //   sortable: true,
        // },
        {
          key: 'process',
          label: i18n.t('message.tableHeader.process'),
          sortable: true,
        },
        {
          key: 'score',
          label: i18n.t('message.tableHeader.score'),
          sortable: true,
        },
        {
          key: 'total',
          label: i18n.t('message.tableHeader.total'),
          sortable: true,
        },
        {
          key: 'adherence',
          label: i18n.t('message.tableHeader.adherence'),
          sortable: true,
        },
        {
          key: 'actions',
          label: i18n.t('message.tableHeader.actions'),
        },
      ]
    })

    async function listConfirmations() {
      isLoading.value = true
      const [startFilter, endFilter] = getDatesFromRange(dateRangeFilter.value)

      let conditions = {
        client_id: { _id: clientId },
        worker: {},
        date_gte: startFilter,
        date_lt: endFilter,
        pending_ne: true
      }

      if (locationFilter.value.length > 0) {
        const locationsFilterQuery = locationFilter.value.map(e => ({locations_in: {_id: e}}))
        conditions.worker.AND = [{OR: locationsFilterQuery}]
      }

      if (zoneFilter.value.length > 0) {
        const zonesFilterQuery = zoneFilter.value.map(e => ({locations_in: {zone: e}}))
        if (conditions.worker.AND) conditions.worker.AND.push({OR: zonesFilterQuery})
        else conditions.worker.AND = [{OR: zonesFilterQuery}]
      }

      if (agencyFilter.value.length > 0) {
        const agenciesFilterQuery = agencyFilter.value.map(e => ({locations_in: {agency: e}}))
        if (conditions.worker.AND) conditions.worker.AND.push({OR: agenciesFilterQuery})
        else conditions.worker.AND = [{OR: agenciesFilterQuery}]
      }

      if (roleFilter.value.length > 0) {
        conditions.worker.roles_in = roleFilter.value
      }

      if (processFilter.value.length > 0) {
        conditions.process = {
          _id_in: processFilter.value,
        };
      }

      if (workerFilter.value.length > 0) {
        conditions.worker._id_in = workerFilter.value
      }

      if (observerFilter.value.length > 0) {
        conditions.supervisor = {
          _id_in: observerFilter.value
        }
      }

      axios
        .post("/graphql", {
          query: queryConfirmationsScore,
          variables: { query: conditions, limit: 10000 },
        })
        .then(({ data }) => {
          if (data.errors) throw new Error(data.errors[0].message)
          parseConfirmationData(data, startFilter)
        })
        .catch((error) => {
          console.log(error);
          handleError({ error, defaultMessage: i18n.t('message.err_confirmation_list') })
        })
        .finally(() => {
          isLoading.value = false
        })
    }

    // Data for adherence XLSX File
    function parseConfirmationData(data, startFilter) {
      excelFields.value = {
        [i18n.t('message.tableHeader.date')]: "dateString",
        [i18n.t('message.tableHeader.worker')]: "worker",
        [i18n.t('message.tableHeader.observer')]: "observer",
        [i18n.t('message.tableHeader.location')]: "location",
        [i18n.t('message.tableHeader.process')]: "process",
        [i18n.t('message.tableHeader.score')]: "score",
        [i18n.t('message.tableHeader.total')]: "total",
        [i18n.t('message.tableHeader.adherence')]: "adherence",
        [i18n.t('message.tableHeader.duration')]: "duration",
        [i18n.t('message.tableHeader.comments')]: "comments"
      }

      // Change the dates so that it matches the UTC time zone
      const timezoneOffsetHours = startFilter.getTimezoneOffset() / 60

      let confirmationData = []
      let adherenceData = {score: 0, total: 0}
      let processData = {}
      let workerData = {}

      for (const confirmation of data.data.confirmations) {
        if (confirmation.total) {
          const date = new Date(confirmation.date)
          date.setHours(date.getHours() + timezoneOffsetHours)
          const processName = confirmation.process?.name || ''
          const processCodeAndName = confirmation.process?.code ? `${confirmation.process.code} - ${processName}` : processName
          const processId = confirmation.process?._id
          const workerName = confirmation.worker?.name
          const workerId = confirmation.worker?._id
          const score = confirmation.score
          const total = confirmation.total
          let comments = ""
          if(confirmation?.activities[confirmation.activities?.length - 1]){
            for (let comentario in confirmation.activities[confirmation.activities.length - 1]?.behaviours){
              if (confirmation.activities[confirmation.activities.length - 1].behaviours[comentario].type === "text"){
                comments = confirmation.activities[confirmation.activities.length - 1].behaviours[comentario].answer
              }
            }
          }
          let payload = {
            date,
            dateString: date.toLocaleDateString(`${default_language || 'en'}-US`),
            worker: workerName,
            observer: confirmation.supervisor?.name,
            location: confirmation.worker?.locations?.map(e => e.location).join(" / "),
            process: processName,
            score: score,
            total: total,
            adherence: processAdherence(score, total),
            adherenceText: function() {
              if (this.total === 0) return "-"
              return `${this.adherence}%`
            },
            duration: confirmation.duration,
            id: confirmation._id,
            workerId: workerId,
            comments: comments
          }

          // Add metadata fields if applicable
          if (confirmation.metadata) {
            confirmation.metadata.forEach(e => {
              payload[e.name] = e.answer
              if (!excelFields.value.hasOwnProperty(i18n.t(`metadata.${e.name}`))) {
                excelFields.value[i18n.t(`metadata.${e.name}`)] = e.name
              }
            })
          }
          
          confirmationData.push(payload)
          adherenceData.score += score
          adherenceData.total += total

          // Data for bar charts
          if (processCodeAndName) updateObjectData(processData, processCodeAndName, processId, score, total)
          if (workerName) updateObjectData(workerData, workerName, workerId, score, total)
        }
      }
      confirmationData.sort(function (a, b) {
        if (b.score === 0 && a.score === 0) return b.total - a.total
        return a.adherence - b.adherence
      })
      confirmations.value = confirmationData
      confirmations_total.value = confirmationData.length
      excelFields.value[i18n.t('message.tableHeader.id')] = "id"
      adherence_index.value = adherenceData.total === 0
        ? "-"
        : `${processAdherence(adherenceData.score, adherenceData.total)}%`

      // Set charts data
      adherenceByProcess.value = setChartsData(processData, "desc")
      workerTopAdherence.value = setChartsData(workerData, "desc", 10)
      workerBottomAdherence.value = setChartsData(workerData, "asc", 10)
    }

    function updateFilter(data) {
      locationFilter.value = data.locationFilter;
      roleFilter.value = data.roleFilter;
      processFilter.value = data.processFilter;
      workerFilter.value = data.workerFilter;
      observerFilter.value = data.observerFilter;
      dateRangeFilter.value = data.dateRangeFilter;
      zoneFilter.value = data.zoneFilter;
      agencyFilter.value = data.agencyFilter;

      listConfirmations();
    }

    const updateObjectData = (object, property, propertyId, score, total) => {
      if (object.hasOwnProperty(property)) {
        object[property].score += score
        object[property].total += total
        object[property].confirmations += 1
      }
      else object[property] = {
        score: score,
        total: total,
        confirmations: 1,
        id: propertyId
      }
    }

    const setChartsData = (objectData, sortDirection, dataLimit) => {
      for (const property in objectData) {
        objectData[property].adherence = processAdherence(objectData[property].score, objectData[property].total)
      }

      let dataSorted = Object.entries(objectData)

      dataSorted.sort(function (a, b) {
        if (b[1].score === 0 && a[1].score === 0) return sortDirection === "desc" ? a[1].total - b[1].total : b[1].total - a[1].total
        return sortDirection === "desc" ? b[1].adherence - a[1].adherence : a[1].adherence - b[1].adherence
      })

      const limit = dataLimit < dataSorted.length ? dataLimit : dataSorted.length

      let labels = []
      let adherenceValues = []
      let confirmationValues = []
      let idValues = []

      for (let i = 0; i < limit; i++) {
        labels.push(dataSorted[i][0])
        adherenceValues.push(dataSorted[i][1].adherence)
        confirmationValues.push(dataSorted[i][1].confirmations)
        idValues.push(dataSorted[i][1].id)
      }

      return {
        labels: labels,
        data: {
          adherence: adherenceValues,
          confirmations: confirmationValues
        },
        additionalInfo: {
          ids: idValues,
        }
      }
    }

    onMounted(() => {
      if (route.value.query.processId) {
        EventBus.$emit("filterByProcess", route.value.query.processId)
      } else {
        listConfirmations()
      }
    })

    return {
      updateFilter,
      confirmations,
      confirmations_total,
      adherence_index,
      adherenceByProcess,
      workerTopAdherence,
      workerBottomAdherence,
      excelFields,
      router,
      isLoading,
      zone_agency_filter,
      tableHeaders,
    };
  },
};
</script>
